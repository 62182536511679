<template>
    <div class="c-add-order">
        <v-title title="Orders - Add" />
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1 class="c-add-order__title">Add new order</h1>
                    <mercur-select data-e2e="shopCountrySelector" v-model="orderCountry" class="c-add-order__currency">
                        <template #label>Select order currency and shop country</template>
                        <option v-for="(value, key) in workingCountriesList" :key="key" :value="key">{{ value.currency }} ({{ key }})</option>
                    </mercur-select>
                </div>
            </div>
        </title-box>
        <template v-if="selectedShop && selectedShop.applicationId !== '0' && orderCountry">
            <div class="container-fluid">
                <customer-selector class="mt-n4 mb-4" :with-create="true" @selected="setCustomer"><p v-if="presetCustomer">You are adding order for <router-link :to="routeCustomer(presetCustomer.accountId, presetCustomer.application.applicationId)"><strong>{{ presetCustomer.firstName }} {{ presetCustomer.lastName }}</strong></router-link></p></customer-selector>
                <form @submit.prevent="submitForm" v-if="customer && customer.organisation.organisationId">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="d-flex align-items-end mb-2" data-e2e="billingAddress">
                                <div class="flex-grow-1">
                                    <h3 class="font-weight-normal">Billing address</h3>
                                    <order-address :address="form.billingInfo.address" />
                                </div>
                                <div>
                                    <mercur-button v-if="hasPermission('MerchantCentral/addAddress')" class="btn btn-primary text-uppercase" @click.native.prevent="triggerModal">add new address</mercur-button>
                                </div>
                            </div>
                            <div>
                                <template v-if="hasPermission('MerchantCentral/listAddresses')">
                                    <customer-addresses class="c-add-order__table mb-4"
                                                        :customOptions="customOptions"
                                                        :organisationId="customer.organisation.organisationId"
                                                        ref="addresses"
                                                        :customerId="customer.accountId"
                                                        :canSetDefault="true"
                                                        :isNotEditable="true"
                                                        @setBilling="setBillingAddress"
                                                        @setShipping="setShippingAddressForOrderLines"
                                    >
                                    </customer-addresses>
                                </template>
                                <template v-else>
                                    Not allowed to see this view
                                </template>
                            </div>
                            <mercur-card>
                                <funnel ref="funnel" @productAdded="addOrderLine" :country="orderCountry"/>
                            </mercur-card>
                            <div class="text-right mt-3">
                                <mercur-button class="btn" to="/orders">Cancel</mercur-button>
                                <mercur-button class="btn btn-primary" data-e2e="addOrderButton" type="submit" :disabled="loading">Add Order</mercur-button>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <template v-if="form.orderLines.length">
                                <mercur-card>
                                    <h3 class="font-weight-normal">OrderLines</h3>
                                    <div class="c-orderline" :data-e2e="`orderline-${index}`" v-for="(item, index) in form.orderLines" :key="index">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <p><strong>{{ item.product.productName }}</strong></p>
                                                <table class="c-add-order__orderline-attributes">
                                                    <tr v-for="(val, key) in item.product.attributes" :key="key">
                                                        <th class="c-add-order__orderline-attributes-key">{{ val.attributeName | capitalize }}</th>
                                                        <td>{{ val.attributeOption | capitalize }}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div class="col-sm-6">
                                                <div>
                                                    <p class="c-add-order__delivery-title">Delivery address</p>
                                                    <order-address :address="item.deliveryInfo.address" />
                                                </div>
                                            </div>
                                        </div>
                                        <p id="orderlinePrice" data-e2e="orderlinePriceForAddOrder">
                                            <strong>Price:
                                                <currency :value="item.productPrice" :type="form.currency" />
                                            </strong>
                                            (vat:
                                            <currency v-if="Object.keys(item.orderLineTotals).length" :value="item.orderLineTotals.productVat"
                                                      :type="form.currency" />
                                            <span v-else>select delivery address to calculate vat</span>
                                            ) <span v-if="Object.keys(item.orderLineTotals).length && item.orderLineTotals.reduction !== '0.00'"> - price after discount <currency :value="item.orderLineTotals.subtotal" :type="form.currency"/></span>
                                        </p>
                                        <p id="orderlineShippingPrice">
                                            <strong>Shipping:
                                                <currency :value="form.freeShipping ? '0' : item.shippingPrice" :type="form.currency" />
                                            </strong>
                                            (vat:
                                            <currency v-if="Object.keys(item.orderLineTotals).length" :value="form.freeShipping ? '0' : item.orderLineTotals.shippingVat"
                                                      :type="form.currency" />
                                            <span v-else>select delivery address to calculate vat</span>
                                            )
                                            <span class="c-add-order__actions">
                                                <span>
                                                    <mercur-tooltip>
                                                        <template #label>Edit orderline</template>
                                                        <mercur-button @click.native.prevent="editOrderLine(item, index)" class="btn btn-icon"><i class="fas fa-edit"></i></mercur-button>
                                                    </mercur-tooltip>
                                                </span>
                                                <span>
                                                    <mercur-tooltip>
                                                        <template #label>Duplicate orderline</template>
                                                        <mercur-button @click.native.prevent="duplicateOrderLine(item)" class="btn btn-icon"><i class="far fa-clone"></i></mercur-button>
                                                    </mercur-tooltip>
                                                </span>
                                                <span>
                                                    <mercur-tooltip>
                                                        <template #label>Delete</template>
                                                        <mercur-button @click.native.prevent="removeOrderLine(index)" class="btn btn-icon"><i class="fas fa-trash"></i></mercur-button>
                                                    </mercur-tooltip>
                                                </span>
                                            </span>
                                        </p>
                                        <hr>
                                        <h4 data-e2e="orderTotal">Total:
                                            <currency :value="totalWithoutVat"
                                                      :type="form.currency" />
                                            (with vat<currency v-if="form.orderLines.every(e => !!Object.keys(e.orderLineTotals).length)" :value="vatTotal"  :type="form.currency"></currency>)
                                        </h4>
                                        <div>
                                            <mercur-checkbox v-model="form.freeShipping">Free shipping</mercur-checkbox>
                                        </div>
                                    </div>
                                </mercur-card>
                            </template>
                        </div>
                    </div>
                </form>
            </div>
            <mercur-dialog :is-open.sync="isDialogOpen">
                <address-form ref="addressForm" :options="addressFormOptions"  @success="success" />
            </mercur-dialog>
            <div>
                <mercur-dialog :is-open.sync="isOrderlinesDialog" width="600px">
                    <template v-if="form.orderLines.length === 0">
                        <h3 class="font-weight-normal">Please first add orderlines before setting the delivery address</h3>
                    </template>
                    <template v-else>
                        <h3 class="font-weight-normal">Select orderlines to change delivery address</h3>
                        <table data-e2e="orderlineTableForAddQuote" class="table">
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>ID</th>
                                    <th>Product</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="orderline in form.orderLines" :key="orderline.orderLineId">
                                    <td><mercur-checkbox v-model="selectedOrderLines" :value="orderline" data-e2e="orderlineTableCheckboxForAddOrder"></mercur-checkbox></td>
                                    <td>{{ orderline.orderLineId }}</td>
                                    <td>{{ orderline.product.productName }}</td>
                                    <td>{{ orderline.product.productPrice.toFixed(2) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <alert type="danger" v-if="hasCountryCurrencyError">Country of the selected address does not match selected currency for the order!</alert>
                    <template #footer>
                        <mercur-button class="btn" @click.native.prevent="isOrderlinesDialog = false">Close</mercur-button>
                        <mercur-button v-if="form.orderLines.length" class="btn btn-primary" data-e2e="orderlineSaveForAddOrder" @click.native.prevent="setAddresses">Save</mercur-button>
                    </template>
                </mercur-dialog>
            </div>
            <edit-dialog :fields="draft.orderLine" ref="editDialog" @change="updateOrderLine" @closed="unsetOrderLine">Edit orderline</edit-dialog>
        </template>
        <div class="container-fluid" v-else>
            <mercur-card class="mt-n3">
                <p>Select a shop and order currency to add an order!</p>
            </mercur-card>
        </div>

    </div>
</template>

<script>
import CONFIG from '@root/config'

import Funnel from '@/components/elements/Funnel'
import CustomerAddresses from '@/components/elements/customers/Addresses'
import OrderAddress from '@/components/elements/order/Address'
import CustomerSelector from '@/components/elements/order/CustomerSelector'
import FormMixin from '@/mixins/Form'
import AddressForm from '@/components/elements/AddressForm'
import EditDialog from '@/components/elements/EditDialog'
import { totalsCalculator } from '@/utils/helpers'
import { mapActions, mapState, mapGetters } from 'vuex'
import countries from 'country-region-data'
import Alert from '../../components/elements/Alert'

export default {
    name: 'AddOrder',
    mixins: [FormMixin],
    components: {
        Funnel,
        Alert,
        CustomerAddresses,
        OrderAddress,
        CustomerSelector,
        AddressForm,
        EditDialog,
    },
    data () {
        return {
            countries,
            selectedOrderLines: [],
            customer: null,
            isDialogOpen: false,
            addressFormOptions: null,
            isOrderlinesDialog: false,
            selectedAddress: null,
            selectedAddressVatProfile: null,
            form: {
                billingInfo: {
                    address: {},
                },
                orderLines: [],
                freeShipping: false,
                discounts: [],
            },
            customOptions: {
                paginationType: 'native',
            },
            draft: {},
            orderCountry: null,
            hasCountryCurrencyError: false,
            customerDefaultAddresses: null,
        }
    },
    computed: {
        ...mapState('order', ['presetCustomer']),
        ...mapState('shop', ['applicationVatProfiles']),
        ...mapGetters('shop', ['workingCountriesList']),
        action () {
            return CONFIG.API.ROUTES.ORDERS.ADD.replace('{accountId}', this.customer.accountId)
        },
        totalWithoutVat () {
            if (!this.form) {
                return
            }
            return totalsCalculator(this.form.orderLines, this.form.freeShipping, this.form.discounts, true)
        },
        vatTotal () {
            if (this.form.freeShipping) {
                return parseFloat(this.form.totals.total) - parseFloat(this.form.totals.shippingPrice) - parseFloat(this.form.totals.shippingVat)
            }
            return this.form.totals.total
        },
    },
    watch: {
        workingCountriesList: {
            handler (value) {
                if (value.length === 1) {
                    this.$set(this, 'orderCountry', Object.keys(value)[0])
                }
            },
            immediate: true,
        },
        customer (value) {
            if (!value) {
                return
            }

            if (value.defaultBillingAddress && Object.keys(value.defaultBillingAddress).length) {
                this.$set(this.form.billingInfo, 'address', value.defaultBillingAddress)
            }
            this.customerDefaultAddresses = { billing: value.defaultBillingAddress, shipping: value.defaultShippingAddress }
        },
    },
    methods: {
        ...mapActions('shop', ['setShopById', 'fetchApplicationVatProfiles']),
        ...mapActions('order', ['setPresetCustomer']),
        submitForm () {
            let isValid = true
            if (!Object.keys(this.form.billingInfo.address).length) {
                this.$root.$emit('notification:global', {
                    message: 'Order is not complete - set billing address',
                })
                return
            }
            if (!this.form.orderLines.length) {
                this.$root.$emit('notification:global', {
                    message: 'Order is not complete - there are no orderlines',
                })
                return
            }
            this.form.orderLines.forEach((orderLine) => {
                if (!orderLine.deliveryInfo.address || !Object.keys(orderLine.deliveryInfo.address).length) {
                    this.$root.$emit('notification:global', {
                        message: 'Order is not complete - set shipping address for orderlines',
                    })
                    isValid = false
                }
            })
            if (!isValid) {
                return
            }

            if (!this.checkShipsTo()) {
                return
            }

            if (this.customer.countryCode) {
                this.form.countryCode = this.orderCountry ? this.orderCountry.toLowerCase() : this.customer.countryCode
            }

            if (this.customer.language) {
                this.form.language = this.customer.language
                if (this.form.language.includes('_')) {
                    this.form.language = this.form.language.replace('_', '-')
                }
            }

            this.cleanUpForm()

            this.submit(this.action, this.form).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Order was added',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'OrdersWrapper',
                    })
                }, 500)
            })
        },

        cleanUpForm () {
            this.$delete(this.form.billingInfo.address, 'totalRows')
            this.form.orderLines.forEach(orderLine => {
                this.$delete(orderLine.deliveryInfo.address, 'totalRows')
            })
        },
        checkShipsTo () {
            if (this.form.orderLines.every(orderLine => !!orderLine.vatProfile)) {
                return true
            }

            if (!this.applicationVatProfiles) {
                this.$root.$emit('notification:global', {
                    message: `Vat profiles for this shop are missing. We are trying to get them for you.`,
                    type: 'error',
                })
                this.fetchApplicationVatProfiles()
                return false
            }

            let isValid = true
            this.form.orderLines.forEach((orderLine) => {
                if (orderLine.vatProfile) {
                    return true
                }

                const address = Object.assign(orderLine.deliveryInfo.address, {})
                address.countryCode = this.countries.find((e) => e.countryName === address.country).countryShortCode
                if (!Object.keys(this.applicationVatProfiles).includes(address.countryCode)) {
                    this.$root.$emit('notification:global', {
                        message: `Shop does not ship to ${address.country}`,
                        type: 'error',
                    })
                    isValid = false
                    return false
                } else {
                    orderLine.vatProfile = this.applicationVatProfiles[address.countryCode]
                }
            })

            return isValid
        },
        addOrderLine (product) {
            const currency = this.$store.getters['shop/calculatedCurrency'](this.orderCountry)
            this.$set(this.form, 'currency', currency)
            let orderLine = {}
            if (product.vatProfile) {
                orderLine.vatProfile = product.vatProfile
                delete product.vatProfile
            }

            orderLine = {
                ...orderLine,
                orderLineId: this.$uuid.v4(),
                product: product,
                productPrice: product.prices.currencySpecificPrices[currency].salesPrice,
                shippingPrice: product.prices.currencySpecificPrices[currency].shippingPrice,
                deliveryInfo: {
                    address: {},
                },
                productVatRate: null,
                shippingVatRate: null,
                orderLineTotals: {},
            }

            if (product.deliveryInfo) {
                orderLine.deliveryInfo = {
                    ...orderLine.deliveryInfo,
                    deliveryDate: product.deliveryInfo.targetDeliveryDate,
                    targetDeliveryDate: product.deliveryInfo.targetDeliveryDate,
                    dispatchDate: product.deliveryInfo.targetDispatchDate,
                    targetDispatchDate: product.deliveryInfo.targetDispatchDate,
                }
                delete product.deliveryInfo
            }

            const customerGroups = this.customer.accountSettings.customerGroups
            if (customerGroups && customerGroups.length) {
                customerGroups.forEach(group => {
                    if (group.services && group.services.includes('FREESHIPPING')) {
                        this.form.freeShipping = true
                    }
                })
            }

            if (this.customerDefaultAddresses && this.customerDefaultAddresses.shipping) {
                orderLine.deliveryInfo.address = this.customerDefaultAddresses.shipping
            }

            this.form.orderLines.push(orderLine)
            this.$refs.funnel.clearFunnel()
        },
        duplicateOrderLine (orderLine) {
            const payload = JSON.parse(JSON.stringify(orderLine))
            payload.orderLineId = this.$uuid.v4()
            this.form.orderLines.push(payload)
        },
        setCustomer (customer, shouldClearPreset = true) {
            this.customer = customer
            this.form.buyer = {
                accountId: customer.accountId,
                buyerInfo: {
                    accountNumber: customer.accountNumber,
                },
            }
            this.form.billingInfo = {
                address: {},
            }
            if (shouldClearPreset) {
                this.setPresetCustomer(null)
            }
        },
        setBillingAddress (address) {
            this.form.billingInfo.address = address
        },
        setShippingAddressForOrderLines (address) {
            if (!this.form.orderLines.length) {
                this.$root.$emit('notification:global', {
                    message: 'There are no orderlines in  this order',
                })
            }
            const selectedCountryShortCode = this.countries.find((e) => e.countryName === address.country).countryShortCode
            this.selectedAddressVatProfile = this.applicationVatProfiles[selectedCountryShortCode]
            this.isOrderlinesDialog = true
            this.selectedAddress = address
            this.hasCountryCurrencyError = false
        },
        calculateTotals () {
            const url = CONFIG.API.ROUTES.ORDERS.CALCULATE_TOTALS.replace('{customerId}', this.customer.accountId)
            const payload = JSON.parse(JSON.stringify(this.form))
            payload.orderLines.forEach((orderLine, index) => {
                if (!Object.keys(orderLine.deliveryInfo.address).length) {
                    payload.orderLines.splice(index, 1)
                }
            })

            this.post(url, payload, 'Totals recalculated').then(({ data }) => {
                this.$set(this.form, 'totals', data.order)
                this.form.orderLines.forEach((orderLine, index) => {
                    if (data.orderLines[orderLine.orderLineId]) {
                        this.$set(this.form.orderLines[index], 'orderLineTotals', data.orderLines[orderLine.orderLineId])
                        this.$set(this.form.orderLines[index], 'productVatRate', data.orderLines[orderLine.orderLineId].productsVatRate)
                        this.$set(this.form.orderLines[index], 'shippingVatRate', data.orderLines[orderLine.orderLineId].shippingVatRate)
                    }
                })
            })
        },
        setAddresses () {
            if (!this.checkIfMatchesOrderCurrency()) {
                this.hasCountryCurrencyError = true
                return
            }
            const ids = this.selectedOrderLines.map((item) => item.orderLineId)
            this.form.orderLines.filter((orderLine) => ids.includes(orderLine.orderLineId)).map((item) => {
                item.deliveryInfo.address = this.selectedAddress
                item.vatProfile = this.selectedAddressVatProfile
            })
            this.isOrderlinesDialog = false
            this.calculateTotals()
        },
        checkIfMatchesOrderCurrency () {
            const addressCountryCode = this.countries.find((e) => e.countryName === this.selectedAddress.country).countryShortCode
            return this.workingCountriesList[addressCountryCode].currency === this.workingCountriesList[this.orderCountry].currency
        },
        triggerModal () {
            this.isDialogOpen = true
            this.addressFormOptions = {
                action: CONFIG.API.ROUTES.CUSTOMERS.ADDRESS.ADD
                    .replace('{customerId}', this.customer.accountId)
                    .replace('{organisationId}', this.customer.organisation.organisationId),
                title: 'Add new address',
                notification: 'Address added',
            }
        },
        removeOrderLine (index) {
            this.form.orderLines.splice(index, 1)
            this.calculateTotals()
        },
        success () {
            this.isDialogOpen = false
            this.$refs.addresses.$refs.table.refreshGrid()
        },
        updateOrderLine (payload) {
            this.$set(this.form.orderLines, [this.draft.index], payload)
            this.$refs.editDialog.resetForm()
            this.calculateTotals()
        },
        unsetOrderLine () {
            this.$set(this, 'draft', {})
        },
        editOrderLine (orderLine, index) {
            const item = JSON.parse(JSON.stringify(orderLine))
            this.$set(this, 'draft', {
                orderLine: {
                    ...item,
                    currency: item.product.prices.currencyCode,
                },
                index: index,
            })
        },
        resetForm () {
            this.$set(this, 'form', {
                billingInfo: {
                    address: {},
                },
                orderLines: [],
                freeShipping: false,
                discounts: [],
            })
        },
        routeCustomer (id, applicationId) {
            return {
                name: 'CustomerView',
                params: {
                    customerId: id,
                    applicationId: applicationId,
                },
            }
        },
        listen () {
            this.unsetOrderLine()
            this.$set(this, 'customer', null)
            this.$set(this, 'customerDefaultAddresses', null)
            this.resetForm()
        },
    },
    mounted () {
        if (this.presetCustomer) {
            this.setCustomer(this.presetCustomer, false)
            this.setShopById(this.presetCustomer.application.applicationId)
        }
        this.$bus.$on('shopWasChangedOnUniqueView', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChangedOnUniqueView', this.listen)
    },
}
</script>
